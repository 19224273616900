import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

let theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 22,
      },
      body1: {
        fontSize: 20,
      },
    },
  },
  palette: {
    primary: blue,
    secondary: orange,
  },
});

theme = responsiveFontSizes(theme, {});
export default theme;
